<template>
  <div>
    <hospitals-component />
    <footer-component />
  </div>
</template>

<script>
import HospitalsComponent from "@/components/hospitals-clinics/HospitalsComponent.vue";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent.vue";

export default {
  name: "Hospitals",
  title: "Hospital Catalog | Baja California Health Tourism",
  components: {
    HospitalsComponent,
    FooterComponent,
  },
};
</script>
