<template>
  <div>
    <v-text-field
      v-model="searchText"
      label="Search"
      @input="performSearch"
      clearable
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "SearchBar",

  props: {
    data: Array,
  },

  data() {
    return {
      searchText: "",
    };
  },
  methods: {
    removeAccents(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },

    performSearch() {
      let filteredData = [];
      if (this.searchText) {
        const sanitizedSearchText = this.removeAccents(this.searchText);
        filteredData = this.data.filter((item) => {
          const nameMatches = this.removeAccents(item.name).includes(
            sanitizedSearchText
          );
          const nameEnglishMatches =
            item.name_english &&
            this.removeAccents(item.name_english).includes(sanitizedSearchText);
          return nameMatches || nameEnglishMatches;
        });
      } else {
        filteredData = this.data;
      }

      this.$emit("search", filteredData);
    },
  },
};
</script>
